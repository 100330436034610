<template>
    <div>
        <page-title :heading="$t('erp.lang_wareOut') " :subheading="$t('erp.lang_wareOut')" :icon=icon></page-title>
        <div class="app-main__inner">
        <GoodsIssue></GoodsIssue>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import GoodsIssue from "../../../../components/erp/storage/goodissue/GoodsIssueComponent";

    export default {
        components: {
            PageTitle,
            GoodsIssue
        },

        data: () => ({
            icon: 'pe-7s-upload icon-gradient bg-tempting-azure',
        })
    }
</script>